export const namespaces = {
  API: 'api',
  FLOW: 'flow',
  ERROR: 'error',
  USER: 'user',
  BRICK: 'brick',
  PLATFORM_CONFIG: 'platformConfig',
  TYPE_LIBRARY: 'types',
  PACKAGE: 'pkg',
  WORKSPACE: 'workspace',
};
